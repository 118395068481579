.resume{
  display: flex;
  align-items: center;
  justify-content: center;
  color:rgb(0, 0, 0)
}

.projectsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #9c9c9c;
  color: white;
}

.projectsList li {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 3px;
  margin-right: 25px;
  padding: 20px;
  background-color: #5c5c5c;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  max-width: 300px; /* adjust the value as needed */
  height: 350px; /* adjust the value as needed */
  overflow: auto;
}

/* Links for Projects Page */

.viewCodeButton{
  color: white;
  background-color: black;
  align-self: center;
}

.viewCodeButton:hover {
  background-color: rgb(140, 0, 0);
}

.inlineHeader {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-weight: 700;
  
}


h4 {
  color: white;
  font-style: bold;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #666666;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.social-links a:hover {
  background-color: #8c8c8c;
}
.active {
  background-color: #4d0000;
}

li:last-child {
  border-right: none;
}

body {
  background-color: #9c9c9c;
}

h2 {
  color: rgb(61, 52, 52);
}

p {
  color: white;
}

#portfolioPic {
  float: right;
  margin-top: -60px;
  margin-right: 10px;
  padding-right: 45px;
  padding-left: 45px;
  border-radius: 35%;
  box-shadow: 0 0 100px rgba(0,0,0,0.0);
}

.about-me{
  margin-left: 50px;
}

.skills{
  margin-left: 50px;
}

.projects{
  margin-left: 50px;
}

.phone, .email {
  float: right;
  padding: 0 15px;
  margin-left: auto;
  margin-right: 100px;
  padding-top: 15px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 15px;
  margin-left: auto;
  margin-right: 100px;
}

.social-links img {
  margin-left: 0px;
}

.name{
  color: white;
  margin-left: 55px;
  margin-top: 7px;
  margin-bottom: 0px;
  font-size: 45px;
  letter-spacing: 5px;
}

/* unvisited link */
a:link {
  color: rgb(0, 0, 0);
}

/* visited link */
a:visited {
  color: rgb(71, 71, 71);
}

/* mouse over link */
a:hover {
  color: rgb(130, 0, 0);
}

/* selected link */
a:active {
  color: rgb(144, 144, 144);
}

@media screen and (max-width: 1300px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 100px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 55px;
  letter-spacing: 5px;
}

.phone, .email {
  float: right;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 13px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 10px;
  margin-left: auto;
  margin-right: 25px;
  padding-top: 20px;
}

.socialsText {
  font-size: 0px;
  margin-left: auto;
}

.socialsTextPhone {
  font-size: 25px;
}
}


@media screen and (max-width: 1070px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 100px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 45px;
  letter-spacing: 5px;
}

.phone, .email {
  float: right;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 13px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  padding-right: 25px;
}

.socialsText {
  font-size: 0px;
  margin-left: auto;
}
}

@media screen and (max-width: 970px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 100px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 3px;
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 35px;
  letter-spacing: 5px;
}

.phone, .email {
  float: right;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 13px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 3px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  padding-right: 20px;
}

.socialsText {
  font-size: 0px;
  margin-left: auto;
}

#portfolioPic{
  padding-top: 15px;
  width: 35%;
  height: 35%
}
}

@media screen and (max-width: 870px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 100px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 35px;
  margin-bottom: 0px;
  font-size: 30px;
  letter-spacing: 2px;
}

.phone, .email {
  float: right;
  padding: 0 5px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 13px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 0px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  padding-right: 25px;
}

.socialsText {
  font-size: 0px;
  margin-left: auto;
}
.socialsTextPhone{
  font-size: 15px;
  margin-top: 30px;
}
}

@media screen and (max-width: 740px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 100px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 38px;
  margin-bottom: 0px;
  font-size: 25px;
  letter-spacing: 2px;
}

.phone, .email {
  float: right;
  padding: 0 5px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 13px;
  padding-left: 0px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  padding: 0 0px;
  margin-right: auto;
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 0px;
}

.socialsText {
  font-size: 0px;
  margin-left: auto;
}
.socialsTextPhone{
  font-size: 12px;
  margin-top: 35px;
}
}

@media screen and (max-width: 650px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 75px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 2px;
}

.phone, .email {
  float: right;
  padding: 0 5px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 5px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  margin-right: auto;
  padding-top: 10px;
  padding-right: 2px;
  padding-left: -2px;
}

.socialsText {
  display: none;
}
.socialsTextPhone{
  font-size: 12px;
  margin-top: 35px;
}
}

@media screen and (max-width: 570px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 75px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 2px;
}

.phone, .email {
  float: right;
  padding: 0 5px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 5px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  margin-right: auto;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: -2px;
}

.socialsText {
  display: none;
}
.socialsTextPhone{
  display: none;
}

.about-me{
  margin-left: 10px;
}

.skills{
  margin-left: 10px;
}

.projects{
  margin-left: 20px;
}
}

@media screen and (max-width: 490px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 75px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 0px;
}

.phone, .email {
  float: right;
  padding: 0 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 0px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  margin-right: auto;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: -2px;
}

.socialsText {
  display: none;
}
.socialsTextPhone{
  display: none;
}

#portfolioPic{
  display: none;
}

.about-me{
  margin-left: 10px;
}

.skills{
  margin-left: 10px;
}

.projects{
  margin-left: 20px;
}
}

@media screen and (max-width: 450px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 55px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  color: white;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 21px;
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0px;
}

.phone, .email {
  float: right;
  padding: 0 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 0px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  margin-right: auto;
  padding-top: 1px;
  padding-right: 0px;
  padding-left: -2px;
}

.socialsText {
  display: none;
}
.socialsTextPhone{
  display: none;
}



.about-me{
  margin-left: 10px;
}

.skills{
  margin-left: 10px;
}

.projects{
  margin-left: 20px;
}


}

@media screen and (max-width: 390px) {

  /* Links for Projects Page */
  
  .viewCodeButton{
    color: white;
    background-color: black;
    align-self: center;
  }
  
  .viewCodeButton:hover {
    background-color: rgb(140, 0, 0);
  }
  
  h4 {
    color: white;
    font-style: bold;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #666666;
    height: 55px;
  }
  
  li {
    float: auto;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  .name{
  display: none;
}

.phone, .email {
  float: right;
  padding: 0 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 0px;
}

.social-links {
  display: flex;
  align-items: center;
  float: right;
  margin-right: auto;
  padding-top: 1px;
  padding-right: 0px;
  padding-left: -2px;
}

.socialsText {
  display: none;
}
.socialsTextPhone{
  display: none;
}

#portfolioPic{
  width: 45%;
  height: 45%;
}
}